import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "imgix"
    }}>{`imgIX`}</h1>
    <ol>
      <li parentName="ol">{`Login to the `}<a parentName="li" {...{
          "href": "https://dashboard.imgix.com/sign-in"
        }}>{`imgIX dashboard`}</a></li>
    </ol>
    <p>{`Note: Make sure billing is set up correctly by the client. There is a 10\\$ free credit on new accounts, which should get you started, even if there is no billing information entered yet.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Sources`}</inlineCode>{` and add a `}<inlineCode parentName="li">{`New source`}</inlineCode>{`.`}</li>
    </ol>
    <p>{`Select source type `}<inlineCode parentName="p">{`Amazon S3`}</inlineCode>{` and add the credentials from the `}<inlineCode parentName="p">{`imgix`}</inlineCode>{` user and enter the `}<inlineCode parentName="p">{`webassets`}</inlineCode>{` bucket name. Enter a subdomain or set up a custom subdomain for the project.
`}<img alt="Step 1" src={require("./imgIX/imgix-1.png")} /></p>
    <p>{`Change `}<inlineCode parentName="p">{`Cache TTL Behaviour`}</inlineCode>{` to `}<inlineCode parentName="p">{`Override origin`}</inlineCode>{`, set a appropriate cache TTL and save the source.
`}<img alt="Step 2" src={require("./imgIX/imgix-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      